.display {
  max-width: 216px;
  max-height: 239px;
  height: 239px;
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 252px;

  @media (max-width: 575px) {
    margin-bottom: 10px;
  }
 
  .imageWrapper {
    display: flex;
    justify-content: center;
    &:hover {
      .media {
        transition: all 0.4s ease;
        opacity: 0.9;
      }

      .magnifier {
        transition: scale 0.4s ease;
        scale: 1;
        cursor: pointer;
      }
    }
  }

  .videoWrapper {
    display: flex;
    justify-content: center;
  }

  .video {
    width: 190px;
    height: 190px;
    object-fit: cover;
    border-radius: 20px;
  }

  .videoSmall {
    width: 80px;
    height: 80px;
  }

  .media {
    transition: all 0.4s ease;
    margin: 0 auto;
    width: 190px;
    height: 190px;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;
  }

  .smallSize {
    width: 80px;
    height: 80px;
  }

  .buttonContainder {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    .button {
      font-size: 0.9rem;
      font-weight: 600;
      color: #666666;
      font-style: normal;
      line-height: 120%;
      background-color: rgba(255, 255, 255, 0);
      display: flex;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;
    }
  }

  .buttonContainderSingl {
    justify-content: center;
  }
}

.smallSize {
  width: 92px;
  height: 118px;
  padding: 0px;
}
