.wrapper {
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  color: #333333;

  .labelText {
    color: white;
  }
}

.root {
position: relative;

  .variatnCountContainer {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    top: -156px;
    border: 1px solid rgba(124, 124, 124, 0.107);
    padding: 0 10px;
    border-radius: 5px;
    width: 54px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.052);
    z-index: 1;
    background-color: white;
    .variatnCountItem {
      padding: 8px 0;
      cursor: pointer;
      color: #333333;
    }
  }

  .currentCount {
    background-color: white;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.074);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 54px;
    border-radius: 5px;
    padding: 10px;
    padding-left: 5px;
    max-height: 30px;
    cursor: pointer;
    .currentCountItem {
      padding-right: 5px;
    }
  }

}