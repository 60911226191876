.root {
  overflow: scroll;
  transition: all 1s ease;
  
  &::-webkit-scrollbar {
    transition: all 1s ease;
    height: 8px;
    background: rgba(128, 128, 128, 0);
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      border-radius: 20px;
    }
  }
  
}