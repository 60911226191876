@use "../../../app/styles/common/var";

.root {
  position: fixed;
  padding-top: 64px;
  width: 255px;
  height: 100%;
  transition: transform 0.4s ease;
  background-color: white;
  z-index: 0;
  border-right: 1px solid var.$border-card;

  @media (max-width: 1200px) {
    transition: transform 0.4s ease;
    transform: translateX(-255px);
    padding-top: 0px;
    z-index: 101;
  }
}

.mobileSideOpen {
  transform: translateX(0px);
}

.shadowContent {
  background-color: #00000080;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  @media (min-width: 1200px) {
    display: none;
  }
}

.asideHeader {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var.$border-card;
}
.asideHeaderContent {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
}

.menuList {
  padding: 8px 16px;
  border-bottom: 1px solid var.$border-card;

  height: 100%;
  max-height: 700px;
  overflow: scroll;
  &::-webkit-scrollbar {
    transition: all 1s ease;
    width: 3px;
    background: rgba(128, 128, 128, 0);
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    opacity: 0.6;
    background-color: var.$bg-header;
    border-radius: 10px;
  }
}

.systemInfo {
  font-size: 14px;
  padding-top: 20px;
  text-align: center;
}
